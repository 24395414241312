<template>
  <footer class="bg-gray-900" aria-labelledby="footerHeading">
    <div class="container pb-12 pt-[71px] lg:pb-16">
      <!-- Reserves -->
      <small
        v-if="route.path.startsWith($i18nPath('/products/'))"
        class="block px-6 mb-12 text-xs text-white"
      >
        {{ $t("common.reserves_right") }}
      </small>
      <!-- Footer above border -->
      <div
        class="px-6 mb-16 overflow-hidden lg:px-0 pb-14 lg:pb-0 lg:grid lg:grid-cols-4"
      >
        <div
          :class="
            checkSocialExist &&
            currentSiteSettings &&
            (currentSiteSettings.office_phone || currentSiteSettings.socials)
              ? 'justify-between'
              : 'justify-start'
          "
          class="flex flex-col gap-8"
        >
          <!-- Logo -->
          <div class="mb-4">
            <nuxt-link :to="$i18nPath('/')">
              <Logo :color="'white'" class="h-10 w-[149px]" />
            </nuxt-link>
          </div>
          <!-- <FooterLogo class="w-[97.84px] h-[150.98px]" /> -->

          <!-- Contacts -->
          <div
            v-if="
              checkSocialExist &&
              currentSiteSettings &&
              (currentSiteSettings.office_phone || currentSiteSettings.socials)
            "
            class="flex justify-center lg:justify-start"
          >
            <!-- Telephone and social networks -->
            <div class="flex flex-col gap-4">
              <!-- Telephone -->
              <a
                v-if="currentSiteSettings.office_phone"
                :href="`tel:${currentSiteSettings.office_phone}`"
                target="_blank"
                class="flex gap-3 text-white hover:text-white"
              >
                <PhoneIcon class="w-5 h-5" />
                <span v-text="currentSiteSettings.office_phone" />
              </a>
            </div>
          </div>
        </div>

        <!-- E-ZI -->
        <Collapsible
          :aria-label="$config.APP_NAME"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="py-3 border-b border-white border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline my-0 text-xl font-semibold text-white uppercase"
              >
                E-ZI
              </h3>
              <PlusIcon
                v-if="!isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <CloseIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>

          <ul class="pt-6 md:pt-[19px] !my-0">
            <!-- eslint-disable-next-line -->
            <li v-if="currentSite.news">
              <nuxt-link
                :to="$i18nPath('/news')"
                class="text-base font-medium text-white hover:no-underline hover:opacity-90"
                v-text="$t('common.news')"
              />
            </li>
            <li>
              <nuxt-link
                :to="$i18nPath('/stores')"
                class="text-base font-medium text-white hover:no-underline hover:opacity-90"
                v-text="$t('common.stores')"
              />
            </li>
          </ul>
        </Collapsible>

        <!-- About Us -->
        <Collapsible
          :aria-label="$config.APP_NAME"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="py-3 border-b border-white border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline my-0 text-xl font-semibold text-white uppercase"
              >
                {{ $t("common.company") }}
              </h3>
              <PlusIcon
                v-if="!isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <CloseIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>
          <ul class="pt-6 md:pt-[19px] !my-0">
            <li v-if="currentSiteSettings.intercom.integrate">
              <a
                href=""
                class="text-base font-medium text-white hover:no-underline hover:opacity-90"
                @click.prevent="intercom.show()"
                v-text="$t('common.chat_now')"
              />
            </li>

            <li>
              <nuxt-link
                :to="$i18nPath('/contact')"
                class="text-base font-medium text-white hover:no-underline hover:opacity-90"
                v-text="$t('common.contact_us')"
              />
            </li>
            <li v-if="currentSiteSettings.test_ride_inquiry">
              <nuxt-link
                :to="$i18nPath('/test-drive')"
                class="text-base font-medium text-white hover:no-underline hover:opacity-90"
              >
                {{ $t("common.test_ride_inquiry.title") }}
              </nuxt-link>
            </li>
            <!-- <li>
              <nuxt-link
                :to="$i18nPath('/work-with-us')"
                class="text-base font-medium text-white hover:no-underline hover:opacity-90"
                v-text="$t('common.work_with_us')"
              />
            </li> -->
          </ul>
        </Collapsible>

        <!-- Group companies -->
        <Collapsible
          :aria-label="$config.APP_NAME"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          class="py-3 border-b border-white border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline my-0 text-xl font-semibold text-white uppercase"
              >
                {{ $t("common.group_companies") }}
              </h3>
              <PlusIcon
                v-if="!isActive"
                class="inline h-5 ml-1 text-white lg:hidden"
              />
              <CloseIcon v-else class="inline h-5 ml-1 text-white lg:hidden" />
            </div>
          </template>
          <div class="flex flex-col lg:flex-row lg:justify-between">
            <ul class="pt-6 md:pt-[19px] my-0">
              <li
                v-for="(company, index) in state.group_companies"
                :key="index"
              >
                <a
                  :href="company.url"
                  target="_blank"
                  class="text-base font-medium text-white hover:no-underline hover:opacity-90"
                >
                  {{ company.name }}
                </a>
              </li>
            </ul>
          </div>
        </Collapsible>
      </div>

      <!-- Social networks -->
      <section
        v-if="
          checkSocialExist && currentSiteSettings && currentSiteSettings.socials
        "
        class="flex justify-center gap-4 px-6 md:justify-end"
      >
        <a
          v-if="currentSiteSettings.socials.facebook"
          :href="currentSiteSettings.socials.facebook"
          target="_blank"
          class="flex gap-3 text-white hover:opacity-90"
        >
          <FacebookIcon class="w-6 h-6 text-white" />
        </a>

        <a
          v-if="currentSiteSettings.socials.instagram"
          :href="currentSiteSettings.socials.instagram"
          target="_blank"
          class="flex gap-3 text-white hover:opacity-90"
        >
          <InstagramIcon class="w-6 h-6 text-white" />
        </a>

        <a
          v-if="currentSiteSettings.socials.linkedin"
          :href="currentSiteSettings.socials.linkedin"
          target="_blank"
          class="flex gap-3 text-white hover:opacity-90"
        >
          <LinkedInIcon class="w-6 h-6 text-white" />
        </a>

        <a
          v-if="currentSiteSettings.socials.twitter"
          :href="currentSiteSettings.socials.twitter"
          target="_blank"
          class="flex gap-3 text-white hover:opacity-90"
        >
          <TwitterIcon class="w-6 h-6 text-white" />
        </a>

        <a
          v-if="currentSiteSettings.socials.youtube"
          :href="currentSiteSettings.socials.youtube"
          target="_blank"
          class="flex gap-3 text-white hover:opacity-90"
        >
          <YouTubeIcon class="w-6 h-6 text-white" />
        </a>

        <a
          v-if="currentSiteSettings.socials.tiktok"
          :href="currentSiteSettings.socials.tiktok"
          target="_blank"
          class="flex gap-3 text-white hover:opacity-90"
        >
          <TikTokIcon class="w-5 h-5 text-white" />
        </a>
      </section>
      <!-- Footer below border -->
      <div
        class="flex flex-col gap-8 px-6 pt-8 mt-4 border-t border-white lg:flex-row lg:gap-4 lg:flex md:items-center md:justify-between"
      >
        <KeewayGroupLogo class="text-gray-600" />

        <div class="flex flex-col gap-4 md:gap-0">
          <!-- Copyright -->
          <p class="text-base font-normal text-gray-600">
            E-ZI &copy; {{ $t("common.copyright") }} - {{ year }} E-ZI -
            {{ $t("common.all_rights_reserved") }}
          </p>

          <!-- Legal -->
          <div class="flex flex-col gap-1 md:flex-row md:gap-4">
            <nuxt-link
              :to="$i18nPath('/terms')"
              class="text-base font-normal text-gray-600 hover:no-underline hover:opacity-90"
            >
              {{ $t("common.terms_and_conditions") }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/privacy-policy')"
              class="text-base font-normal text-gray-600 hover:no-underline hover:opacity-90"
            >
              {{ $t("common.privacy_policy") }}
            </nuxt-link>
            <nuxt-link
              :to="$i18nPath('/cookie-policy')"
              class="text-base font-normal text-gray-600 hover:no-underline hover:opacity-90"
            >
              {{ $t("common.cookie_policy") }}
            </nuxt-link>
          </div>
        </div>

        <!-- Locales -->
        <LocaleSelector
          class="flex justify-center md:block"
          name="footer"
          position="bottom"
        />
      </div>
    </div>
    <MemeLink />
  </footer>
</template>
<script setup>
import { useMainStore } from "@/store/index";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import PlusIcon from "@/assets/svg/heroicons/plus.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import PhoneIcon from "@/assets/svg/heroicons/phone.svg?component";
import { useLocaleStore } from "@/store/locales";
import FacebookIcon from "~/assets/svg/heroicons/facebook.svg?component";
import TwitterIcon from "~/assets/svg/heroicons/twitter.svg?component";
import InstagramIcon from "~/assets/svg/heroicons/instagram.svg?component";
import YouTubeIcon from "~/assets/svg/heroicons/youtube.svg?component";
import LinkedInIcon from "~/assets/svg/heroicons/linkedin.svg?component";
import TikTokIcon from "~/assets/svg/heroicons/tiktok.svg?component";

const store = useMainStore();
const nuxtApp = useNuxtApp();
const route = useRoute();
const localeStore = useLocaleStore();

const $i18nPath = nuxtApp.$i18nPath;
const $config = nuxtApp.$config.public;
const browserWidth = ref(0);
const hasMenuDisplay = ref(false);
const state = reactive({ group_companies: [] });
const year = ref(new Date().getFullYear());
const intercom = ref(
  typeof nuxtApp.$intercom !== "undefined" ? nuxtApp.$intercom : null
);

const products = store.products;
const sites = store.sites;
const country = store.country;
const locales = localeStore.defaultLocales;
const currentSiteSettings = store.currentSiteSettings;
const currentSite = store.currentSite;
const features = store.features;

const textClass = computed(() => {
  return store.locale === "el"
    ? "text-[17px] 2xl:text-[20px] font-bold"
    : "text-[30px]";
});

const countryName = computed(() => {
  const countryData = locales.length
    ? locales.find((x) => x.slug === country)
    : null;
  return countryData ? countryData.country.name : "";
});

const checkSocialExist = computed(() => {
  let data = [
    "facebook",
    "instagram",
    "linkedin",
    "twitter",
    "youtube",
    "tiktok",
  ];
  if (!currentSiteSettings) {
    return null;
  }
  return data.find((_v) => currentSiteSettings.socials[_v]);
});

const checkBrowserWidth = computed(() => {
  return browserWidth.value >= 1024 ? true : false;
});

onMounted(() => {
  const findData = features.find((_v) => _v.slug == "group-companies");

  if (findData && findData.settings) {
    state.group_companies = findData.settings;
  }
  browserWidth.value = window.innerWidth;
  hasMenuDisplay.value = true;
  window.addEventListener("resize", windowResized);
});

onUnmounted(() => {
  window.removeEventListener("resize", windowResized);
});

function windowResized() {
  browserWidth.value = window.innerWidth;
}
</script>
