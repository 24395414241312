<template>
  <div>
    <div class="relative block lg:hidden z-[9999]">
      <div
        class="fixed inset-0 top-0 z-20 h-16 transition-all"
        :class="
          scrollPosition >= 80 || isStorePage
            ? 'shadow backdrop-brightness-50 backdrop-filter backdrop-blur '
            : 'bg-transparent'
        "
      >
        <nav
          class="container flex items-center justify-between h-16"
          :class="[scrollPosition >= 80 || isStorePage ? 'pt-0' : '']"
        >
          <a
            href="#mmenu"
            tabindex="0"
            aria-label="Mobile sidebar mmenu toggle"
            class="z-10 text-xs font-semibold text-white md:text-base lg:text-xl hover:no-underline focus:no-underline hover:text-primary-500 focus:outline-none"
            @click.prevent="open"
          >
            <MobileMenuIcon class="w-[18px] h-[16px]" :color="'#D4D4D4'" />
          </a>

          <a
            class="absolute flex items-center justify-center transform cursor-pointer -translate-s-1/2 start-1/2"
            :class="scrollPosition >= 80 ? 'top-[10px]' : ''"
            @click.prevent="onClickLink($i18nPath('/'))"
            href="#"
            :aria-label="`E-ZI ${$t('common.home_title')}`"
          >
            <Logo
              :color="
                scrollPosition >= 80 || isStorePage || isProductPage
                  ? '#F54308'
                  : 'white'
              "
              class="transition-all h-11"
            />
          </a>

          <div class="flex">
            <!-- Locale selector -->
            <LocaleSelector name="headermobile" />
            <!-- <UserDropdown v-if="store.enableShop" /> -->
          </div>
        </nav>
      </div>
      <nav v-show="visible" id="mmenu" ref="mmenu">
        <ul>
          <!-- Home -->
          <li
            :class="isActive('home') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath('/'))"
          >
            <a
              href="#"
              :aria-label="$t('common.home_title')"
              v-text="$t('common.home_title')"
            />
            <div></div>
          </li>

          <li v-if="store.products.length >= 1">
            <span v-text="$t('common.products')" />
            <ul>
              <li v-for="(cat, cIndex) in products" :key="cIndex">
                <span v-text="cat.name" />
                <ul>
                  <template v-for="(products, materialKey) in cat.products">
                    <li
                      v-for="(product, prdIndex) in products"
                      :key="`${materialKey}-${prdIndex}`"
                    >
                      <a
                        class="product__category"
                        @click.prevent="
                          onClickLink($i18nPath(`/products/${product.slug}`))
                        "
                      >
                        <span v-text="product.name" />
                        <img
                          v-if="product.image"
                          :src="product.image"
                          loading="lazy"
                        />
                      </a>
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
          </li>

          <!-- Accesories -->
          <li v-if="categories.length > 0">
            <span v-text="$t('common.accesories')" />
            <ul>
              <li v-for="(cat, cIndex) in categories" :key="cIndex">
                <span v-text="cat.name" />
                <ul>
                  <li
                    v-for="(subCat, subCatIndex) in cat.children"
                    :key="`sub-${subCatIndex}`"
                  >
                    <a
                      class="product__category"
                      @click.prevent="
                        onClickLink(
                          $i18nPath(
                            `/search/accessories?category=${subCat.original_name}`
                          )
                        )
                      "
                    >
                      <span v-text="subCat.name" />
                      <img
                        v-if="subCat.image"
                        :src="subCat.image"
                        loading="lazy"
                      />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <!-- News -->
          <li
            v-if="currentSite && currentSite.news"
            :class="isActive('news') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath('/news'))"
          >
            <a
              :aria-label="$t('common.news')"
              href="#"
              v-text="$t('common.news')"
            />
            <div></div>
          </li>

          <!-- Shop online -->
          <li
            v-if="store.enableShop"
            :class="isActive('search') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath(`/search`))"
          >
            <a
              :aria-label="$t('common.shop_online')"
              href="#"
              v-text="$t('common.shop_online')"
            />
            <div></div>
          </li>
          <!-- Stores -->
          <!-- v-if="store.enableShop" -->
          <li
            :class="isActive('stores') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath(`/stores`))"
          >
            <a
              :aria-label="$t('common.stores')"
              href="#"
              v-text="$t('common.stores')"
            />
            <div></div>
          </li>
          <!-- Contact us -->
          <li
            :class="isActive('contact') ? 'is-active' : ''"
            @click.prevent="onClickLink($i18nPath(`/contact`))"
          >
            <a
              href="#"
              :aria-label="$t('common.contact_us')"
              v-text="$t('common.contact_us')"
            />
          </li>
        </ul>
        <div
          class="w-full h-[60px] bg-white flex justify-center items-center absolute bottom-0 z-50"
        >
          <button @click="close">
            <CloseIcon class="h-9 w-h-9 text-primary-500" />
          </button>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import { useTranslation } from "i18next-vue";
import MmenuLight from "mmenu-light/esm/core/index";

const store = useMainStore();
const route = useRoute();
const router = useRouter();
const { t } = useTranslation();

const visible = ref(false);
const isProductPage = ref(false);
const isStorePage = ref(false);
const isMenuShow = ref(false);
const scrollPosition = ref(0);
const mmenu = ref(null);
const drawer = ref(null);

const products = store.products;
const categories = store.categories;
const currentSite = store.currentSite;
const currentSiteSettings = store.currentSiteSettings;

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    if (newValue.name == "country-products-slug") {
      isProductPage.value = true;
    } else {
      isProductPage.value = false;
    }
    if (newValue.name == "country-stores") {
      isStorePage.value = true;
    } else {
      isStorePage.value = false;
    }
  },
  { deep: true }
);

const loadModule = () => {
  if (mmenu.value) {
    const menu = new MmenuLight(mmenu.value, "(max-width: 1024px)");
    /* eslint-disable-next-line */
    const navigator = menu.navigation({
      title: t("common.menu"),
    });
    drawer.value = menu.offcanvas({
      position: store.isRtl ? "right" : "left",
    });
  }
};

onMounted(() => {
  isProductPage.value = window.location.href.includes("/products/");
  isStorePage.value = window.location.href.includes("/stores");

  loadModule();

  window.addEventListener("scroll", updateScroll);

  var elements = document.querySelectorAll(".mm-ocd__backdrop");

  elements[0].addEventListener("touchend", onToggleMenu);
  elements[0].addEventListener("mouseup", onToggleMenu);

  visible.value = true;
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
  removeListener();
});

const removeListener = () => {
  var elements = document.querySelectorAll(".mm-ocd__backdrop");

  elements[0].removeEventListener("touchend", onToggleMenu);
  elements[0].removeEventListener("mouseup", onToggleMenu);
};
const isActive = (active) => {
  if (active == "home" && route.name == "country") {
    return true;
  }
  return route.path.includes(active);
};
const onToggleMenu = () => {
  isMenuShow.value = false;
};
const onClickLink = (route) => {
  if (drawer.value) {
    drawer.value.close();
  }
  isMenuShow.value = false;
  router.push(route);
};
const open = () => {
  if (drawer.value) {
    drawer.value.open();
  }
  isMenuShow.value = true;
};
const close = () => {
  if (drawer.value) {
    drawer.value.close();
  }
  isMenuShow.value = false;
};
const updateScroll = () => {
  scrollPosition.value = window.scrollY;
};
</script>

<style lang="postcss">
@import "mmenu-light/dist/mmenu-light.css";

div.locale-selector {
  padding: 0 2rem;
}

.mm-ocd__content {
  -webkit-transition-duration: 0s !important;
  -o-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.mm-spn.mm-spn--light {
  @apply bg-white;
}

.mm-spn li:after {
  @apply border-gray-400;
}

.mm-spn a:not(:last-child):after {
  @apply border-transparent;
}

.mm-spn.mm-spn--navbar:after {
  @apply font-bold uppercase bg-gray-100 text-black opacity-60;
}

.mm-spn.mm-spn--navbar ul:before {
  @apply border-transparent;
}
</style>
